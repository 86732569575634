import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface NavigationContextType {
  navOpen: boolean;
  toggled: boolean;
  toggleNav: () => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

const isLargeScreen = () => window.innerWidth >= 1024;

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [navOpen, setNavOpen] = useState(true);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (isLargeScreen()) {
        setNavOpen(true);
      }
      setToggled(true);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once on mount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleNav = () => {
    if (!isLargeScreen()) {
      setNavOpen(!navOpen);
    }
  };

  return (
    <NavigationContext.Provider value={{ navOpen, toggleNav, toggled }}>
      {children}
    </NavigationContext.Provider>
  );
};
