import { FC, useContext, useEffect } from "react";
import { useNavigation } from "../context/NavigationContext";
import { GatewayContext } from "../context/GatewayContext";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { IoMdMenu } from "react-icons/io";

const Header: FC = () => {
  const { toggleNav } = useNavigation();
  const { connected } = useWallet();
  const { gateway, setGateway } = useContext(GatewayContext);

  useEffect(() => {
    if (!connected && gateway) {
      setGateway(null);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <header className="z-10">
      <nav className="bg-[#242526] px-4 py-2 lg:p-4 lg:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-1">
            <a className="flex text-gray-50" href="/">
              <svg
                className="h-8 w-40 lg:h-10 lg:w-48"
                viewBox="0 0 384 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M37 74C57.4345 74 74 57.4345 74 37C74 16.5655 57.4345 0 37 0C16.5655 0 0 16.5655 0 37C0 57.4345 16.5655 74 37 74ZM39.3649 15.125L22.0929 36.0889C20.9965 37.4197 22.1544 39.3949 23.8512 39.0883L31.879 37.6375C33.2396 37.3916 34.3799 38.6696 33.9811 39.9935L28.8768 56.9374C28.3058 58.8328 30.7072 60.1813 32.0284 58.7073L51.4167 37.076C52.5287 35.8354 51.5644 33.872 49.9029 33.9936L41.1669 34.6328C39.9348 34.723 38.9616 33.6041 39.2217 32.3964L42.6039 16.6921C43.0125 14.7952 40.5988 13.6274 39.3649 15.125Z"
                  fill="#00FF84"
                />
                <path
                  d="M124.937 31.4715C136.054 32.7516 143.802 35.5814 143.802 46.3614C143.802 54.1769 137.065 61.0492 124.398 61.0492C111.193 61.0492 104.994 52.5599 104.994 43.8011H113.888C114.022 47.4394 115.707 53.5032 124.398 53.5032C132.281 53.5032 134.639 49.528 134.639 46.9004C134.639 43.6664 132.551 41.3083 124.398 40.2303C110.923 38.4785 106.611 34.0991 106.611 26.0141C106.611 18.6028 113.281 12.539 124.533 12.539C136.863 12.539 141.916 19.9503 142.051 27.6311H133.359C133.359 24.5318 131.271 20.085 124.398 20.085C117.459 20.085 115.707 23.5886 115.707 25.6098C115.707 27.4289 116.583 30.5282 124.937 31.4715Z"
                  fill="white"
                />
                <path
                  d="M162.804 24.3971C173.921 24.3971 180.591 32.6168 180.591 42.7231C180.591 52.8294 173.921 61.0492 162.804 61.0492C151.687 61.0492 145.017 52.8294 145.017 42.7231C145.017 32.6168 151.687 24.3971 162.804 24.3971ZM162.804 53.2337C168.665 53.2337 171.967 48.5174 171.967 42.7231C171.967 36.9289 168.665 32.2126 162.804 32.2126C156.942 32.2126 153.641 36.9289 153.641 42.7231C153.641 48.5174 156.942 53.2337 162.804 53.2337Z"
                  fill="white"
                />
                <path
                  d="M195.694 61.0492C188.014 61.0492 182.893 56.0634 182.893 45.9572V24.9361H191.517V42.9926C191.517 51.0103 193.606 53.2337 198.12 53.2337C203.106 53.2337 207.687 47.6415 207.687 39.4217V24.9361H216.311V60.5102H207.687V58.2868C207.687 56.1308 208.159 54.8507 208.765 53.4358C209.035 52.8294 209.439 51.8862 208.563 51.6841C208.024 51.5493 207.822 51.9536 207.553 52.4926C205.801 55.9961 201.893 61.0492 195.694 61.0492Z"
                  fill="white"
                />
                <path
                  d="M229.246 32.5495C229.651 32.6168 229.987 32.0778 230.055 31.8757C232.211 26.9573 234.165 24.3971 242.856 24.3971V33.1558C232.817 32.4821 229.92 36.053 229.92 45.0813V60.5102H221.296V24.9361H229.92V26.8899C229.92 28.4396 229.516 29.7871 228.909 30.9998C228.64 31.6062 228.438 32.4147 229.246 32.5495Z"
                  fill="white"
                />
                <path
                  d="M241.924 42.7231C241.924 32.1452 248.864 24.3971 259.913 24.3971C269.885 24.3971 275.814 31.1346 276.42 38.4111H267.931C267.392 35.0424 264.764 32.2126 259.913 32.2126C253.984 32.2126 250.548 36.5246 250.548 42.7231C250.548 48.9217 253.984 53.2337 259.913 53.2337C264.764 53.2337 267.392 50.4039 267.931 47.0352H276.42C275.814 54.3117 269.952 61.0492 259.913 61.0492C249.066 61.0492 241.924 53.3011 241.924 42.7231Z"
                  fill="white"
                />
                <path
                  d="M312.363 42.2515C312.363 43.1948 312.296 43.8685 312.161 44.8791H286.289C286.895 50.2692 290.399 53.5032 295.789 53.5032C299.899 53.5032 301.987 51.2798 302.998 48.1132H311.757C310.207 55.4571 305.221 61.0492 295.722 61.0492C284.2 61.0492 277.867 53.3011 277.867 42.7231C277.867 31.6062 284.739 24.3971 295.587 24.3971C303.807 24.3971 312.363 29.3155 312.363 42.2515ZM286.626 38.9501H303.739C303.402 34.6381 300.842 31.4041 295.856 31.4041C289.995 31.4041 287.502 34.7055 286.626 38.9501Z"
                  fill="white"
                />
                <path
                  d="M329.136 19.546C325.498 19.546 325.43 19.8155 325.43 23.7233V24.9361H335.132V32.4821H325.43V60.5102H316.806V32.4821H311.416V24.9361H316.806V22.9148C316.806 14.3581 320.175 12 327.317 12H335.132V19.546H329.136Z"
                  fill="white"
                />
                <path
                  d="M349.157 61.0492C341.477 61.0492 336.356 56.0634 336.356 45.9572V24.9361H344.98V42.9926C344.98 51.0103 347.069 53.2337 351.583 53.2337C356.569 53.2337 361.15 47.6415 361.15 39.4217V24.9361H369.774V60.5102H361.15V58.2868C361.15 56.1308 361.622 54.8507 362.228 53.4358C362.498 52.8294 362.902 51.8862 362.026 51.6841C361.487 51.5493 361.285 51.9536 361.016 52.4926C359.264 55.9961 355.356 61.0492 349.157 61.0492Z"
                  fill="white"
                />
                <path
                  d="M374.759 12H383.383V60.5102H374.759V12Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <div className="hidden items-center lg:flex">
            <WalletMultiButton />
          </div>
          <button className="lg:hidden">
            <IoMdMenu className="text-white" size={30} onClick={toggleNav} />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
