import { FC, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { useWallet } from "@solana/wallet-adapter-react";
import { Gateway, GatewayContext } from "../context/GatewayContext";
import { UilCircuit } from '@iconscout/react-unicons';

interface GatewayListProps {
  onNavigate: () => void;
}

const GatewayList: FC<GatewayListProps> = ({ onNavigate }) => {
  const { connected, publicKey } = useWallet();
  const { data } = useQuery({
    queryKey: ["walletGateways"],
    queryFn: async () => {
      const query = JSON.stringify({
        query: `{
                gateway{
                    gateways(wallet:"${publicKey?.toString()}", take:100, showTestMode: true){
                        name
                        id
                }}}`,
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });
      const json = await response.json();
      return json.data.gateway.gateways.map((f: any): Gateway => {
        return { name: f.name, serial: f.id };
      });
    },
    enabled: !!publicKey,
  });

  const { setGateway } = useContext(GatewayContext);

  useEffect(() => {
    if (!data || !connected) return;
    if (data.length === 1) {
      setGateway(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, connected]);

  return (
    <div className="flex w-full items-center text-white">
      {connected && data && data.length > 0 && (
        <div className="flex flex-col p-2">
          <h2 className="my-4 font-srcful text-xl font-semibold">Gateways</h2>
          <ul className="flex flex-col items-center justify-around space-y-1 font-medium md:items-start">
            {data.map((gateway: Gateway) => (
              <li key={gateway.serial} className="w-full">
                <Link
                  to="/gateway/$serial"
                  params={{ serial: gateway.serial }}
                  className="group flex w-full items-center rounded-lg p-2 font-medium"
                  activeProps={{
                    className: "!bg-green-600"
                  }}
                  onClick={() => {
                    setGateway(gateway);
                    onNavigate();
                  }}
                >
                  <UilCircuit className="h-6 w-6" />
                  <span className="ml-2 whitespace-nowrap">{gateway.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {connected && data && data.length === 0 && (
        <div
          className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
          role="alert"
        >
          <span>Could not find any connected gateways 😭</span>
        </div>
      )}
    </div>
  );
};

export default GatewayList;
