import Header from "../../components/Header";
import Aside from "../../components/layout/Aside";
import { useNavigation } from "../../context/NavigationContext";
import { Outlet } from "@tanstack/react-router";
import { useRef, useEffect } from "react";

const MainLayout = () => {
  const { navOpen, toggleNav } = useNavigation();
  const asideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (window.innerWidth < 1024 && navOpen && asideRef.current && !asideRef.current.contains(event.target as Node)) {
        toggleNav();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navOpen, toggleNav]);

  return (
    <div className="flex h-full flex-col bg-[#1d1d1d]">
      <Header />
      <div className="relative flex h-full w-full overflow-hidden">
        {navOpen && (
          <div className="fixed inset-0 z-30 bg-black/50 lg:hidden" />
        )}
        <div
          ref={asideRef}
          className={`${
            navOpen ? "translate-x-0" : "-translate-x-full"
          } fixed inset-y-0 left-0 z-40 w-72 overflow-hidden border-r border-gray-600/80 bg-[#1d1d1d] transition-transform duration-300 lg:relative lg:w-96 lg:translate-x-0`}
        >
          <Aside />
        </div>
        <main className="flex-1 overflow-auto bg-[#1d1d1d]">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
