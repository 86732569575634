import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { createMessageStr, signSrcfulMessage } from "../../messaging/Message";

// Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

interface GatewayLocationFormProps {
  serial: string;
}

export default function GatewayLocationForm({ serial }: GatewayLocationFormProps) {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [zoom] = useState<number>(4);
  const [lngLat, setLngLat] = useState({ lng: 10.9, lat: 50.2 });
  const { publicKey, signMessage } = useWallet();

  const { refetch, isFetching } = useQuery({
    queryKey: ["signLocationChange"],
    queryFn: async () => {
      if (!publicKey || !signMessage) return;
      const toastId = toast.loading("Waiting for signature...");

      console.log("serial: ", serial)
      console.log("lngLat: ", lngLat)

      //CREATE MESSAGE
      const message = createMessageStr({
        wallet: publicKey.toString(),
        durationInSec: 60,
        messageData: {
          Latitude: lngLat.lat.toFixed(6),
          Longitude: lngLat.lng.toFixed(6),
          "Gateway Serial": serial,
        },
        Statement:
          "By signing this message, I confirm that the location of the gateway is correct.",
      });

      //SIGN MESSAGE
      const signedMessage = await signSrcfulMessage(signMessage, message);

      if (!signedMessage) {
        toast.remove(toastId);
        return;
      }

      //UPDATE
      const query = JSON.stringify({
        query: `
          mutation($location: LocationInput!) {
            setLocation(location: $location){
              success
            }
          }`,
        variables: {
          location: {
            message: signedMessage.message,
            signature: signedMessage.signature,
          },
        },
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      toast.loading("Sending data ...", {
        id: toastId,
      });

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });
      toast.dismiss(toastId);
      toast.success("Successfully saved location!");
      return await response.json();
    },
    enabled: false,
  });

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [lngLat.lng, lngLat.lat],
        zoom: zoom,
        minZoom: 2.7,
      });
    }
  }, [lngLat.lat, lngLat.lng, zoom]);

  useEffect(() => {
    if (!map.current) return;

    // Define a new marker with custom SVG
    const el = document.createElement('div');
    el.innerHTML = `
    <svg height="40px" width="40px" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#010002;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#010002;" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>
    `;
    el.style.cursor = 'pointer';

    const marker = new mapboxgl.Marker(el, { offset: [0, -20] })
      .setLngLat(map.current.getCenter())
      .addTo(map.current);

    map.current.on('move', () => {
      const center = map.current!.getCenter();
      setLngLat({
        lng: center.lng,
        lat: center.lat
      });
      marker.setLngLat([center.lng, center.lat]);
    });
  }, []);

  return (
    <>
      <div className="mt-4 flex h-screen max-h-[300px] min-h-full w-full flex-col justify-center gap-4 sm:flex-row md:max-h-[300px] md:min-h-fit">
        <div className="bg-glass h-full w-full overflow-hidden">
          <div ref={mapContainer} className="h-full w-full">
            <div style={{ 
              position: 'absolute',
              top: '20px', left: '20px',
              background: 'white', padding: '10px',
              borderRadius: '8px', boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
              zIndex: 1,
              color: 'black'
            }}>
              <p>Longitude: {lngLat.lng.toFixed(6)}</p>
              <p>Latitude: {lngLat.lat.toFixed(6)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 mt-6 flex flex-wrap justify-center items-center w-full">
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            refetch();
          }}
        >
          <button
            disabled={isFetching}
            type="submit"
            className="ml-auto flex items-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isFetching && (
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            <span>{isFetching ? "Processing" : "Assert Location"}</span>
          </button>
        </form>
      </div>
    </>
  );
} 