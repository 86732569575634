import { FC, useContext, useEffect } from "react";
import { useParams } from "@tanstack/react-router";
import { useWallet } from "@solana/wallet-adapter-react";
import { useQuery } from "@tanstack/react-query";
import { GatewayContext } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import InfoBox from "../components/InfoBox";
import GatewayLocationForm from "../components/form/GatewayLocationForm";

const Gateway: FC = () => {
  const { serial } = useParams({ from: "/gateway/$serial" });
  const { connected } = useWallet();
  const { gateway, setGateway } = useContext(GatewayContext);

  useEffect(() => {
    if (serial && !gateway) {
      const query = JSON.stringify({
        query: `{
          gateway{
            gateway(id:"${serial}"){
              name
              id
            }
          }
        }`,
      });

      const fetchGateway = async () => {
        const response = await fetch("https://api.srcful.dev/", {
          method: "POST",
          body: query,
          headers: {
            "Content-Type": "application/json",
            "Content-Length": query.length.toString(),
          },
        });
        const json = await response.json();
        const data = json.data.gateway.gateway;
        setGateway({ name: data.name, serial: data.id });
      };

      fetchGateway();
    }
  }, [serial, gateway, setGateway]);

  const { data } = useQuery({
    queryKey: ["getGwBasics"],
    queryFn: async () => {
      if (!gateway) return;

      const query = JSON.stringify({
        query: `
          {
            gateway{
              gateway(id:"${gateway?.serial}"){
                h3Index
                name
                id
                wallet
              }
            }
          }`,
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });

      var json = await response.json();
      return json.data.gateway.gateway;
    },
    enabled: !!gateway,
    refetchInterval: 500,
  });

  if (!connected || !gateway || !data) return null;

  return (
    <ContentFramework>
      <div className="my-4 flex w-full flex-col items-center gap-4 md:my-0">
        {/* Gateway Information */}
        <div className="bg-glass w-full p-4 text-gray-200 lg:w-1/2 dark:text-white">
          <h1 className="mb-10 font-srcful text-4xl font-bold">
            {gateway.name}
          </h1>
          <div className="w-full">
            <div>
              <h2 className="text-base font-bold leading-7">
                Energy Gateway Information
              </h2>
            </div>
            <div className="mt-5">
              <dl className="divide-y divide-gray-200">
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">Name</dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {data.name}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Serial / Id
                  </dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {data.id}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Wallet publickey
                  </dt>
                  <dd className="text-md mt-1 truncate leading-6 sm:col-span-2 sm:mt-0">
                    {data.wallet}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">H3-Index</dt>
                  <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                    {data.h3Index}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {/* Location Section */}
        <div className="bg-glass w-full p-4 text-gray-200 lg:w-1/2 dark:text-white">
          <InfoBox
            heading="Gateway position"
            body="Please provide the precise latitude and longitude of your DER for accurate verification. Inaccurate location may reduce your rewards. Your exact location is only shared with Sourceful and will not appear in the explorer."
          />
          <GatewayLocationForm serial={gateway.serial} />
        </div>
      </div>
    </ContentFramework>
  );
};

export default Gateway; 