import { LightFramework } from "../components/ContentFramework";
//import MapBox from "../components/map/MapBox";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Srcful from "../assets/srcful-bg.png";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { FC } from "react";

const Index: FC = () => {

  return (
    <LightFramework>
      <div className="grid h-full w-full grid-cols-1 place-items-center gap-4 bg-[#1d1d1d] p-4 font-srcful lg:grid-cols-2">
        <div className="bg-glass relative flex h-full w-full overflow-hidden lg:col-span-2">
          <div
            className="absolute inset-0 z-0"
            style={{
              backgroundImage: `url(${Srcful})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              filter: "brightness(10%) saturate(100%) blur(2px) contrast(75%)",
            }}
          ></div>
          <div className="z-10 flex-grow p-4 md:p-8 lg:p-24">
            <h1 className="mb-8 text-4xl font-extrabold text-white md:mb-12 lg:text-7xl">
              Sourceful Energy
            </h1>
            <p className="mb-8 text-lg font-semibold text-white lg:mb-14 lg:text-xl">
              Log in with your Solana wallet <WalletMultiButton />
            </p>
            <p className="mb-8 text-lg font-semibold text-white lg:mb-14 lg:text-xl">
              After connecting, proceed by selecting{" "}
              <span className="text-[#00bf63]">Onboard New Gateway</span> from
              the navbar. <br />
              <span className="text-sm text-red-400">
                Please note that iPhones are currently not supported for onboarding.
              </span>
            </p>
          </div>
        </div>
        <div className="bg-glass flex h-full w-full flex-col p-4 text-white md:p-8 lg:p-12">
          <h2 className="mb-8 flex-1 text-2xl font-semibold md:mb-12 md:text-3xl">
            Setup Guide
          </h2>
          <div className="flex items-center gap-8">
            <a
              href="https://github.com/srcfl/egw-getting-started"
              rel="noreferrer"
              target="_blank"
              className="rounded-lg bg-[#00bf63] p-8"
            >
              <ArrowRightIcon className="h-6 w-6 fill-current" />
            </a>
            <p className="text-lg">Open guide</p>
          </div>
        </div>
        <div className="bg-glass flex h-full w-full flex-col items-start p-4 text-white md:p-8 lg:p-12">
          <h2 className="mb-8 flex-1 text-2xl font-semibold md:mb-12 md:text-3xl">
            Questions?
          </h2>
          <div className="flex items-center gap-8">
            <a
              href="https://discord.gg/PkYumtwY3y"
              rel="noreferrer"
              target="_blank"
              className="rounded-lg bg-[#00bf63] p-8"
            >
              <ArrowRightIcon className="h-6 w-6 fill-current" />
            </a>
            <p className="text-lg">Join our Discord</p>
          </div>
        </div>
      </div>
    </LightFramework>
  );
};

export default Index;
