import GatewayList from "../GatewayList";
import { Link } from "@tanstack/react-router";
import {
  HomeIcon,
  WrenchScrewdriverIcon,
  SignalIcon,
} from "@heroicons/react/24/solid";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useNavigation } from "../../context/NavigationContext";
import { motion as m } from "framer-motion";
import { useContext } from "react";
import { GatewayContext } from "../../context/GatewayContext";

export default function Aside() {
  const { navOpen, toggled, toggleNav } = useNavigation();
  const { setGateway } = useContext(GatewayContext);

  const Links = [
    { to: "/", label: "Home", Icon: HomeIcon, onClick: () => setGateway(null) },
    { to: "/onboard-gw", label: "Onboard Gateway", Icon: WrenchScrewdriverIcon },
    { to: "/connect-gw", label: "Connect Gateway", Icon: SignalIcon },
  ];

  const variants = {
    open: {
      x: 0,
      opacity: 1,
      display: "block",
      transition: { type: "spring", stiffness: 300, damping: 30 }
    },
    closed: {
      x: "-100%",
      opacity: 0,
      transition: {
        type: "spring", 
        stiffness: 300, 
        damping: 30,
        opacity: { duration: 0.2 }
      },
      transitionEnd: {
        display: "none"
      }
    }
  };

  return (
    <m.aside
      animate={navOpen ? "open" : "closed"}
      initial={toggled ? "closed" : false}
      variants={variants}
      className="fixed inset-y-0 left-0 z-40 h-full w-72 flex-col overflow-hidden border-r border-gray-600/80 bg-[#1d1d1d] text-white lg:static lg:z-auto lg:w-96"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="mb-2 h-[#100dvh] w-full lg:h-full">
        <div className="overflow-y-auto px-5 py-6">
          <ul className="flex flex-col items-center justify-around space-y-1 font-medium md:items-start">
            {Links.map(({ to, label, Icon, onClick }) => (
              <li key={to} className="w-full">
                <Link
                  activeProps={{
                    className: "bg-green-600 font-bold",
                  }}
                  to={to}
                  onClick={() => {
                    if (onClick) onClick();
                    if (window.innerWidth < 1024) toggleNav();
                  }}
                  className="group flex items-center rounded-lg p-2"
                >
                  <Icon className="h-6 w-6 fill-current" />
                  <span className="ml-2">{label}</span>
                </Link>
              </li>
            ))}
          </ul>
          <div className="my-4 border-t border-gray-600/80" />
          <GatewayList onNavigate={() => {
            if (window.innerWidth < 1024) toggleNav();
          }} />
        </div>
      </div>
      <div className="block p-4 lg:hidden">
        <WalletMultiButton />
      </div>
    </m.aside>
  );
}
