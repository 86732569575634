import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

import { useBle } from "../../context/BleContext";
import * as ble from "../../services/ble/";

import toast from "react-hot-toast";

export default function FormWiFi({
  updateCompletion,
}: {
  updateCompletion: (isCompleted: boolean) => void;
}) {
  // useEGW connections
  const bleApi = useBle();

  const [ssid, setSSID] = useState<string>("");

  // useState varaibles
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [ssids, setSSIDs] = useState<string[]>([]);
  const [manualInput, setManualInput] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateCompletion(true);

    const payload = {
      ssid: ssid,
      psk: password,
    };

    const toastId = toast.loading("Setting wifi credentials...\nPatience is advised");
    try {
      setIsSubmitting(true);
      const response = await bleApi.fetch(ble.API_WIFI, ble.Method.POST, payload);
      console.log("WiFi credentials: ", response);
      if (response.status === "EGWTP/1.1 200 OK" && response.payload.status === "ok") {
        toast.remove(toastId);
        toast.success("Wifi credentials set");
      } else {
        toast.remove(toastId);
        if (response.status !== "EGWTP/1.1 200 OK") {
          toast.error("Setting wifi credentials failed, status: " + response.status);
        } else {
          toast.error("Setting wifi credentials failed, status: " + response.payload.status);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.remove(toastId);
      toast.error("Setting wifi credentials failed");
      setIsSubmitting(false);
    }
  };

  // ask for ssids
  useEffect(() => {
    async function getSSIDs() {
      try {
        const response = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
        console.log("WiFi credentials: ", response);
        if (response.payload.ssids) {
          setSSIDs(response.payload.ssids);
        }


      } catch (error) {
        console.log(error);
      }
    }
    getSSIDs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // useEffect(() => {
  //   console.log('useEffect called');
  //   async function getNetworkStuff() {
  //     try {
  //       const response = await bleApi.fetch('/api/network', ble.Method.GET, {});
  //       console.log("network blob: ", response);

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getNetworkStuff();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="space-y-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="network">Network SSID:</label>
          <select
            className="rounded-lg p-2 text-black outline-none"
            id="network"
            value={ssid}
            onChange={(e) => setSSID(e.target.value)}
            disabled={isSubmitting}
          >
            <option value="">Select a Network</option>
            {ssids.map((ssid: string, index: number) => (
              <option key={index} value={ssid}>
                {ssid}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <label htmlFor="networkManual">Manual input SSID:</label>
            <input
              className="h-4 w-4 rounded-lg"
              type="checkbox"
              name="networkManual"
              id="networkManual"
              checked={manualInput}
              onChange={(e) => setManualInput(e.target.checked)}
              disabled={isSubmitting}
            />
          </div>
          {manualInput && (
            <input
              type="text"
              placeholder="Enter SSID manually"
              className="mt-2 rounded-lg p-2 text-black outline-none"
              onChange={(e) => setSSID(e.target.value)}
              disabled={isSubmitting}
            />
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="password">Password</label>
          <div className="flex">
            <input
              placeholder="Password"
              className="w-full rounded-l-lg p-2 text-black outline-none"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              disabled={isSubmitting}
            ></input>
            <button
              className="rounded-r-lg bg-white px-4"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <FaEyeSlash className="text-black" size={20} />
              ) : (
                <FaEye className="text-black" size={20} />
              )}
            </button>
          </div>
          <button className="btn-green mt-4 space-y-2" disabled={isSubmitting}>Submit</button>
        </div>
      </div>
    </form>
  );
}
