import { useState, useEffect } from "react";

import ConnectedInfoBoxTable from "./ConnectedInfoBoxTable";
import ConnectedInfoBoxRow from "./ConnectedInfoBoxRow";
import * as ble from "../../services/ble/";

interface ConnectedInfoBoxProps {
  bleApi: ble.Api;
}

const ConnectedInfoBox: React.FC<ConnectedInfoBoxProps> = ({ bleApi }) => {
  const [name, setName] = useState("-");
  const [uptime, setUptime] = useState(0);
  const [inverter, setInverter] = useState("fetching...");
  const [ipaddress, setIPAddress] = useState("fetching...");
  const [version, setVersion] = useState("fetching...");
  const [serialNumber, setSerialNumber] = useState("fetching...");
  const [pubkey, setPubkey] = useState("fetching...");

  // we disable the update on send etc as this will cause exlosion of requests
  // these functions should probably not be part of the react component at all.

  // useEffect(() => {
  //   console.log("ConnectedInfoBox");

    // ask for name
  //   async function getNameAndUptime() {
  //     try {
  //       const nameResponse = await bleApi.fetch(
  //         ble.API_NAME,
  //         ble.Method.GET,
  //         {},
  //       );
  //       // when name is defined then nameResponse.payload.name is defined
  //       // when nameResponse.payload.exception is defined then we have an error
  //       if (nameResponse.payload.name !== undefined) {
  //         setName(nameResponse.payload.name);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getNameAndUptime();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // then ask for uptime at some interval
  // useEffect(() => {
  //   const sendId = setInterval(async () => {
  //     if (bleApi.manager.isConnected()) {
  //       try {
  //         const uptimeResponse = await bleApi.fetch(
  //           ble.API_UPTIME,
  //           ble.Method.GET,
  //           {},
  //         );
  //         setUptime(uptimeResponse.payload.msek);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       clearInterval(sendId);
  //     }
  //   }, 30000);
  //   return () => {
  //     clearInterval(sendId);
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // If the name has been set, stop checking for it
      if (name !== "-" || !bleApi.manager.isConnected()) {
        clearInterval(intervalId);

        // now we are connected to ask for the inverter status
        try {
          console.log("fetching inverter");
          const response = await bleApi.fetch(
            ble.API_INVERTER,
            ble.Method.GET,
            {},
          );
          setInverter(response.payload.status);
        } catch (error) {
          console.log(error);
        }

        try {
          console.log("fetching ipaddress");
          const response = await bleApi.fetch(
            ble.API_IPADDRESS,
            ble.Method.GET,
            {},
          );
          setIPAddress(response.payload.ip + ":" + response.payload.port);
        } catch (error) {
          console.log(error);
        }

        try {
          console.log("fetching version");
          const response = await bleApi.fetch(
            ble.API_VERSION,
            ble.Method.GET,
            {},
          );
          setVersion(response.payload.version);
        } catch (error) {
          console.log(error);
        }

        try {
          console.log("fetching serial number");
          const response = await bleApi.fetch(
            ble.API_CRYPTO,
            ble.Method.GET,
            {},
          );
          setSerialNumber(response.payload["serialNumber"]);
          setPubkey(response.payload["publicKey"]);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const nameResponse = await bleApi.fetch(
            ble.API_NAME,
            ble.Method.GET,
            {},
          );
          if (nameResponse.payload.name !== undefined) {
            setName(nameResponse.payload.name);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 10000); // Adjust the interval as needed

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  // increase uptime every second is nice
  useEffect(() => {
    const updateId = setInterval(() => {
      if (uptime > 0) {
        setUptime(() => {
          return uptime + 1000;
        });
      }
    }, 1000);

    return () => {
      clearInterval(updateId);
    };
  });

  // msek to hh:mm:ss
  const msekToTime = (msek: number) => {
    const seconds = Math.floor(msek / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  };

  // useEffect to react to changes in bluetooth.responses
  useEffect(() => {
    const observerCallback = (response: ble.EGWTPResponse) => {
      if (response.location === ble.API_UPTIME) {
        setUptime(response.payload.msek);
      } else if (response.location === ble.API_INVERTER) {
        setInverter(response.payload.status);
      }
    };

    bleApi.subscribe(observerCallback);

    return () => {
      bleApi.unsubscribe(observerCallback);
    };
  }, [bleApi]);

  return (
    <ConnectedInfoBoxTable>
      <ConnectedInfoBoxRow
        label="Status"
        value={name !== "-" && name !== undefined ? "Online" : "Offline"}
        valueColor={
          name !== "-" && name !== undefined ? "text-green-500" : "text-red-500"
        }
      />
      <ConnectedInfoBoxRow
        label="Gateway Name"
        value={name || "-"}
        isCopyable={true}
      />
      <ConnectedInfoBoxRow
        label="IP-Address"
        value={ipaddress}
        isCopyable={true}
      />
      <ConnectedInfoBoxRow
        label="Serial Number"
        value={serialNumber}
        isCopyable={true}
      />
      <ConnectedInfoBoxRow
        label="Public Key"
        value={pubkey}
        displayValue={`${pubkey.slice(0, 15)}...`}
        isCopyable={true}
      />
      <ConnectedInfoBoxRow label="Uptime" value={msekToTime(uptime)} />
      <ConnectedInfoBoxRow label="Firmware" value={version} />
      <ConnectedInfoBoxRow
        label="Inverter connection"
        value={inverter}
        isCopyable={true}
      />
    </ConnectedInfoBoxTable>
  );
};

export default ConnectedInfoBox;
